.default-loading {
}

/* https://www.w3schools.com/howto/howto_css_loader.asp */
.default-loading .loader {
  width: 120px;
  height: 120px;

  position: absolute;
  left: 50%;
  top: 35%;
  z-index: 1;

  /* margin: -75px 0 0 -75px; */
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
